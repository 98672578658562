'use client';

import { useAppSelector } from '@akinon/next/redux/hooks';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

export default function MobileAppToggler({
  children
}: {
  children: React.ReactNode;
}) {
  const searchParams = useSearchParams();
  const { isMobileApp } = useAppSelector((state) => state.root);
  const [isInIframe, setIsInIframe] = useState(false);

  useEffect(() => {
    if (window.frameElement) {
      setIsInIframe(true);
    }
  }, []);

  const insiderPlat = searchParams.get('insiderPlat');

  if (
    !insiderPlat &&
    (isMobileApp || isInIframe || searchParams.get('iframe') === 'true')
  ) {
    return null;
  }

  return <>{children}</>;
}
