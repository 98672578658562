'use client';
import { Button, Checkbox, Modal } from '@theme/components';
import clsx from 'clsx';
import { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useLocalization } from '@akinon/next/hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans } from '@akinon/next/components/trans';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';

import {
  useGetProfileInfoQuery,
  useUpdateProfileMutation
} from '@akinon/next/data/client/account';

import { useToast } from '@theme/hooks/use-toast';
import { useSession } from 'next-auth/react';

type AccountProfileKvkkAgrementType = {
  first_name: string;
  last_name: string;
  phone: string;
  date_of_birth: string;
  gender: string;
  sms_allowed: boolean;
  email_allowed: boolean;
  kvkk_confirm?: boolean;
  confirm?: boolean;
};
const registerFormSchema = (t) =>
  yup.object().shape({
    confirm: yup
      .boolean()
      .oneOf([true], t('auth.register.form.error.required_agreement')),
    kvkk_confirm: yup
      .boolean()
      .oneOf([true], t('auth.register.form.error.required_agreement'))
  });

export const GlobalModal = () => {
  const { status } = useSession();

  if (status !== 'authenticated') return null;

  return <ProfileModal />;
};

const ProfileModal = () => {
  const [openGlobalModal, setOpenGlobalModal] = useState(true);
  const { data: profileInfo, isSuccess, isLoading } = useGetProfileInfoQuery();
  if (isLoading && !isSuccess) return null;
  if (
    !!profileInfo?.attributes?.kvkk_confirm ||
    !!profileInfo?.attributes?.confirm
  )
    return null;

  return (
    <Modal
      portalId="portal-kvkk-confirmation"
      isClosable={false}
      open={openGlobalModal}
      className="w-full md:max-w-lg"
      classNameHeader="hidden"
    >
      <KvkkMigrationForm setOpenGlobalModal={setOpenGlobalModal} />
    </Modal>
  );
};

const KvkkMigrationForm = ({ setOpenGlobalModal }) => {
  const { t } = useLocalization();
  const { data: profileInfo, refetch } = useGetProfileInfoQuery();
  const [openModal, setOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState({
    title: undefined,
    description: undefined
  });

  const [updateProfile] = useUpdateProfileMutation();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AccountProfileKvkkAgrementType>({
    mode: 'onChange',
    resolver: yupResolver(registerFormSchema(t)),
    defaultValues: {
      kvkk_confirm: false,
      confirm: false
    }
  });

  const { data: membership } = useGetWidgetQuery('membership-agreement');
  const { data: kvkk } = useGetWidgetQuery('kvkk-agreement');

  const handleSuccess = () => {
    toast.open({
      message: `${t('account.my_profile.form.success.description')}`
    });
    refetch();
    setOpenGlobalModal(false);
  };
  const handleOpenModal = (flag) => {
    flag === 'membership' &&
      setContentModal({
        title: membership?.attributes?.title?.value,
        description: membership?.attributes?.description?.value
      });

    flag === 'kvkk' &&
      setContentModal({
        title: kvkk?.attributes?.title?.value,
        description: kvkk?.attributes?.description?.value
      });

    setOpenModal(true);
  };

  const onSubmit: SubmitHandler<AccountProfileKvkkAgrementType> = async (
    data
  ) => {
    const payload = {
      ...profileInfo,
      attributes: JSON.stringify({
        ...profileInfo?.attributes,
        kvkk_confirm: data.kvkk_confirm,
        confirm: data.confirm
      })
    };
    try {
      await updateProfile(payload).unwrap().then(handleSuccess);
    } catch (error) {
      console.error('Failed to update profile:', error);
    }
  };

  return (
    <div className="flex flex-col items-center p-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="!text-sm text-black-400 md:text-xs">
          <div className="mb-8 items-start text-sm font-medium">
            {t('account.my_profile.modal.description')}
          </div>
          <Checkbox
            className={clsx('font-medium', errors.confirm ? 'mb-8' : 'mb-6')}
            {...register('confirm')}
            error={errors.confirm}
            data-testid="register-agreement-1"
            wrapperClassName="!items-start"
            labelClassName="text-primary leading-[16px]"
          >
            <Trans
              i18nKey="auth.register.form.agreements.membership.title"
              components={{
                Membership: (
                  <label
                    htmlFor="membership"
                    className="cursor-pointer underline"
                  >
                    <span
                      id="membership"
                      onClick={() => handleOpenModal('membership')}
                    >
                      {t('auth.register.form.agreements.membership.label')}
                    </span>
                  </label>
                )
              }}
            />
          </Checkbox>

          <Checkbox
            className={clsx('font-medium', errors.confirm ? 'mb-8' : 'mb-6')}
            {...register('kvkk_confirm')}
            error={errors.kvkk_confirm}
            data-testid="register-agreement-1"
            wrapperClassName="!items-start"
            labelClassName="text-primary leading-[16px]"
          >
            <Trans
              i18nKey="auth.register.form.agreements.kvkk_checkbox.title"
              components={{
                Kvkk: (
                  <label htmlFor="kvkk" className="cursor-pointer underline">
                    <span id="kvkk" onClick={() => handleOpenModal('kvkk')}>
                      {t('auth.register.form.agreements.kvkk_checkbox.label')}
                    </span>
                  </label>
                )
              }}
            />
          </Checkbox>
        </div>
        <Button
          className="mt-4 h-12 w-full"
          type="submit"
          data-testid="register-submit"
        >
          {t('auth.register.form.submit')}
        </Button>
      </form>
      <Modal
        title={contentModal.title}
        portalId="portal-modal-container"
        open={openModal}
        setOpen={setOpenModal}
        className="w-3/4 md:max-w-xl"
      >
        <div
          className="max-h-80 overflow-auto p-4"
          dangerouslySetInnerHTML={{ __html: contentModal.description }}
        />
      </Modal>
    </div>
  );
};
